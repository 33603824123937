
import React from 'react'
import { Link } from 'react-router-dom'
import '../Componant Style/DashboardHead.css'

import add from '../assets/add.svg'
import search from '../assets/search.svg'


export default function DashborardHeadProduct() {
    return (
        <div className='headerDashboard'>
              <div className='searchBarDash'>
                    <div>
                        <img src={search} alt=''/>
                        <input type="text" placeholder='Search for a Model'/>
                    </div>
              </div>
              <Link to='/Dashboard/addProduct' className='addArticle'>
                <img src={add} alt=''/>
                <p>Ajouter article</p>
              </Link>
        </div>
      )
}
