import React, {useEffect, useState, useCallback} from 'react'
import { useParams, Link, useLocation,useNavigate } from 'react-router-dom';
import axios from 'axios';
import { baseURL } from '../config.js';


// import Componant
import ProductItem from '../Componant/Product';
import Header from '../Componant/Header';
import ListProduct from '../Componant/ListProduct';
import SearchBar from '../Componant/SearchBar';
import Footer from '../Componant/Footer';

// import CSS
import '../Componant Style/searchBar.css'
import '../Page Style/ProductPage.css'

// import Assets
import arrUp from '../assets/arrow-up.svg'
import arrDown from '../assets/arrow-down.svg' 
import panel from '../assets/panel.svg'
import ellipse from '../assets/ellipse.svg'
import ellipseW from '../assets/ellipseW.svg'
import noData from '../assets/noData.png'

export default function Product() {

  const serverBaseUrl = `${baseURL}/`;
  const location = useLocation();
  const { filter } = useParams();
  const navigate = useNavigate();

  const [promotionData, setPromotionData] = useState({});
  const [laptopData, setlaptopData] = useState({});
  const [printerData, setprinterData] = useState({});
  const [pcData, setpcData] = useState({});
  const [allData, setallData] = useState({});
  const [panelN, setPanel] = useState()

  const [cat, setCat] = useState([])
  const [sousCat, setSousCat] = useState([])
  const [categoryNames, setCategoryNames] = useState([]);

  const [categorie, setCategorie] = useState("")

  const Nos_Promotions = 'Nos Promotions';
  const Lap_Top = 'Lap Top'
  const Pc_a_table = 'Pc a table'
  const All_in_one = 'All in one' 
  const Imprimantes = 'Imprimantes'

  const [filteredData,setfiltredData] = useState({})
  

useEffect(()=>{
  const fetchData = async () => {
    try {
      // Fetch data using async/await
      await getNumberOfElementInPanel();

      const response1 = await axios.get(`${baseURL}/accueilPromotion`);
      setPromotionData(response1.data);

      const response2 = await axios.get(`${baseURL}/accueilProduct?condition=Lap%25`);
      setlaptopData(response2.data);

      const response3 = await axios.get(`${baseURL}/accueilProduct?condition=All%25`);
      setallData(response3.data);

      const response4 = await axios.get(`${baseURL}/accueilProduct?condition=Pc%25`);
      setpcData(response4.data);

      const response5 = await axios.get(`${baseURL}/accueilProductCate?condition=Impr%25`);
      setprinterData(response5.data);

      const response6 = await axios.get(`${baseURL}/getCategorie`);
      setCat(response6.data);

      const names = response6.data.map((category) => category.categorie_name);
      setCategoryNames(names);

      const response7 = await axios.get(`${baseURL}/getSousCategories`);
      setSousCat(response7.data);

      const id = normalizePath(location.pathname)
      console.log(id)
      if(id === "Tous" || id ==='Choisir la categorie') {
        setfiltredData({})
      }else{
        const response8 = await axios.get(`${baseURL}/pageProduct?id=${id}`);
        setfiltredData(response8.data);
      }

      window.scrollTo(0, 0);
    } catch (error) {
      // Handle errors for both requests here
      console.error('Error fetching data:', error);
    }
  };


  fetchData(); // Call the fetchData function

}, []);


const searchBarProduct = (data) => {
setfiltredData(data)
}


  const changePath=()=>{
    let result = null;

    if (sousCat.find(item => item.sous_categorie_name === normalizePath(location.pathname))) {
      result = sousCat.find(item => item.sous_categorie_name === normalizePath(location.pathname));
    } else if (cat.find(item => item.categorie_name === normalizePath(location.pathname))) {
      result = cat.find(item => item.categorie_name === normalizePath(location.pathname));
    }

    if (result) {
      setCategorie(result.categorie_name);
    } 
  } 

  useEffect(() => {
    // Initial load
    changePath()
    const fetchData = async () => {
      try {
        const id = normalizePath(location.pathname)
        if(id === "Tous" || id ==='Choisir la categorie') {
          setfiltredData({})
        }else{
          const response8 = await axios.get(`${baseURL}/pageProduct?id=${id}`);
          setfiltredData(response8.data);
        }
  
      } catch (error) {
        // Handle errors for both requests here
        console.error('Error fetching data:', error);
      }
    };
  
  
    fetchData();
    
  }, [location.pathname] );

 
  const handleSelectChange = (event) => {
    const selectedValue = event.target.id;
    navigate(`/Product/${selectedValue}`);
};

  function normalizePath20(path) {
    // Replace %20 with a space
    const normalizedPath = path.replace(/%20/g, ' ')
    return normalizedPath;
  }
  
  function normalizePath(path) {
    // Replace %20 with a space
    const normalizedPath = path.replace(/%20/g, ' ').replace(/\/Product\//g, '')
    return normalizedPath;
  }
 
  const normalizedPath = normalizePath20(location.pathname);

  const isActive = (pathname) => {
    return pathname === normalizedPath ? 'activee-link' : 'notActibe';
};

const linkActive =(pathname)=>{
    return pathname === normalizedPath ? ellipseW : ellipse
}
  
  const getNumberOfElementInPanel = () =>{
    const storedArray = JSON.parse(localStorage.getItem('id'));
    const lengthOfArray = storedArray ? storedArray.length : 0;
    
    setPanel(lengthOfArray);
  
  }

  const toggleCategoryName = useCallback(
    (categoryName) => {
      setCategoryNames((prevCategoryNames) => {
        if (prevCategoryNames.includes(categoryName)) {
          // If category name is already in the array, remove it
          return prevCategoryNames.filter((name) => name !== categoryName);
        } else {
          // If category name is not in the array, add it
          return [...prevCategoryNames, categoryName];
        }
      });
    },
    [] // Empty dependency array indicates that the function doesn't depend on any props or state
  );


  return (
    <div>
        <div className='headerSearch'>
            <Header/>
            <SearchBar searchFunction = {searchBarProduct}/>
        </div>

        <div className='containerPageProduct'>
    
                <ul className='ListCategorieProduct'>

                  { cat.map((item, index) => (
                  <li key={index} className='sideBarItem'>

                    <div  className={`itemProduct ${categorie === item.categorie_name? 'active-linkk' : 'noActive-linkk'}`} >
                        <img className='iconsSideBar'  src={serverBaseUrl + 'uploads/' + item.icon} alt=''/>
                        <div >
                          <p className='nameCategoryProduct' id={item.categorie_name} onClick={handleSelectChange} >{item.categorie_name}</p>
                          <img  onClick={() => {toggleCategoryName(item.categorie_name);}}  className='arrowIcon' src={categoryNames.includes(item.categorie_name) ? arrDown:arrUp} alt=''/>
                        </div>
                    </div> 

                    
                      <ul className={`ListInsideList ${categoryNames.includes(item.categorie_name) ? 'hidden' : 'visible'}`}>
                      { sousCat.map((sousitem, sousindex) => (
                        ( sousitem.categorie_name === item.categorie_name ? <li key={sousindex}><p id={sousitem.sous_categorie_name} onClick={handleSelectChange} className={`linkInsideList ${isActive(`/Product/${sousitem.sous_categorie_name}`)}`}><img id={sousitem.sous_categorie_name} onClick={handleSelectChange} src={linkActive(`/Product/${sousitem.sous_categorie_name}`)} alt=''/>{sousitem.sous_categorie_name}</p></li> : null)
                          ))}
                      </ul>
                    

                  </li>
                  ))}
               </ul>

            { location.pathname.replace('/Product/', '')==='Choisir%20la%20categorie' || location.pathname.replace('/Product/', '').replace("%20", ' ') ==='Tous'?             
            <div className='productContainerProduct'>
                <ListProduct getNumberOfElementInPanel = {getNumberOfElementInPanel} title={Nos_Promotions} data = {promotionData}/>
                <ListProduct getNumberOfElementInPanel = {getNumberOfElementInPanel} title={Lap_Top}  data = {laptopData}/>
                <ListProduct getNumberOfElementInPanel = {getNumberOfElementInPanel} title={Pc_a_table} data = {pcData}/>
                <ListProduct getNumberOfElementInPanel = {getNumberOfElementInPanel} title={All_in_one} data = {allData}/>
                <ListProduct getNumberOfElementInPanel = {getNumberOfElementInPanel} title={Imprimantes} data = {printerData} />
            </div> : 
            <div className='productContainerProduct'>
                <h1>{filter}</h1>
                <div className='productContainerProductFiltred'>
                  {filteredData.length>0?filteredData.map((item,index)=>{
                    return(
                    <ProductItem 
                      key={index}
                      id={item.idproduct}
                      image = {item.productImagePath}
                      pro={item.promotion}
                      model={item.model}
                      brand={item.brand}
                      prix={item.prix}
                      promotion={item.promotion}
                      getNumberOfElementInPanel = {getNumberOfElementInPanel}
                    />)
                  }): <div className='noDataProduct'>
                          <img src={noData} alt="aucun produit" />
                          <p>Aucun produit Disponible</p>
                      </div>}
                </div>
            </div>
            }

        </div>
       <Link className='panelContainer' to='/Commands'>
        <div className='panel'>
          <img id="panelImg" src={panel} alt=''/>
          <p id="panelTxt">{panelN}</p>
        </div>
      </Link>

        <Footer/>
    </div>
  )
}
