import React, { useEffect, useState, useCallback } from 'react'

import axios from 'axios'
import { baseURL } from '../config.js';

import remove from '../assets/remove.svg'
import eye from '../assets/eye.svg'

import DeleteCountainer from './DeleteCountainer'
import { Link } from 'react-router-dom'
export default function CommandAdmin() {

  const [deleteId, setDeleteId] = useState();
    
    const [delConf, setDelConf] = useState(false)

    const [data, setData] = useState([]);

    const showDeletConfermation = (id) =>{
        setDeleteId(id);
        setDelConf(true)
    }

    const onClickDeleteOk = () => {
      axios
        .delete(`${baseURL}/deleteCommandAdemin?id=${deleteId}`)
        .then((response) => {
          // Handle success
          console.log('Delete successful', response.data);
          setDelConf(false);
          // Update the state with the new data
          fetchData();
        })
        .catch((error) => {
          // Handle error
          alert('Error deleting resource', error);
        });
    };

    const fetchData = useCallback(async () => {
      try {
        const response = await  axios.get(`${baseURL}/getDataCommand `)
        setData(response.data);
      } catch (error) {
        console.error('Error:', error);
      }
    }, []);

    useEffect(() => {
      // Call fetchData when the component mounts or when props.type changes
      fetchData();
    }, [fetchData]);

  return (
    <div className='containerTable'>
    <div>
        <p className='titleTables'>All Command</p>
        <table>
            <thead>
                <tr>
                    <th>Nom</th>
                    <th>Prenom</th>
                    <th>Wilaya</th>
                    <th>Commun</th>
                    <th colSpan={3}>adresse</th>
                    <th>Telephone</th>
                    <th>Date</th>
                    <th>Price</th>
                    <th>Actions</th>
                </tr>
            </thead> 
            <tbody>
            { data.length>0 ? data.map((item, index) => (
                <tr key={index}>
                    <td>{item.nom}</td>
                    <td>{item.prenom}</td>
                    <td>{item.wilaya}</td>
                    <td>{item.commune}</td>
                    <td colSpan={3} className='descTable'>{item.adresse}</td>
                    <td>{item.numero_tel}</td>
                    <td>{item.date_commande}</td>
                    <td>{item.price}</td>
                    <td className='images'>
                    <img className='supptable' src={remove} onClick={() => showDeletConfermation(item.id)} alt='remove' key={item.idproduct} />
                      <Link to={`/Dashboard/CommandDetail/${item.id}`}><img className='eyetable' src={eye} alt='eye' key={item.idproduct}/></Link> 
                    </td>
                </tr>
                    ))
                    :
                    <tr><td>no Data</td></tr>}
            </tbody>
        </table>
    </div>
    <DeleteCountainer state={delConf} setstate={setDelConf} onClickDeleteOk={onClickDeleteOk} />
</div>

  )
}
