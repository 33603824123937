import { Routes, Route } from 'react-router-dom';
import './App.css';
import Accueil from './Pages/Accueil';
import Propos from './Pages/Propos';
import Entreprises from './Pages/Entreprises';
import Partner from './Pages/Partner';
import Product from './Pages/Product';
import Login from './Pages/Login';
import Dashboard from './Pages/Dashboard';
import PageNotFound from './Pages/PageNotFound';
import ServerError from './Pages/ServerError';
import DetailProduct from './Pages/DetailProduct';
import DetailProductAdmin from './Componant/DetailProduct';
import CommandClient from './Pages/CommandClient';
import CommandDetail from './Componant/CommandDetail';



function App() {
  return (
    <>  
      <Routes>
        <Route index element={<Accueil />}/>
        <Route path="Propos" element={<Propos />} />
        <Route path="Product/:filter" element={<Product/>} />
        <Route path="Entreprises" element={<Entreprises />} />  
        <Route path="Partner" element={<Partner />} /> 
        <Route path="Login" element={<Login />} />
        <Route path="ProductDetail/:productId" element={<DetailProduct/>} />
        <Route path="/Dashboard/DetailProductAdmin/:productId" element={<DetailProductAdmin/>} />
        <Route path="/Dashboard/commandDetail/:id" element={<CommandDetail/>} />
        <Route path="Commands" element={<CommandClient/>} />
        <Route path="Dashboard/:filter" element={<Dashboard/>}/>
        <Route path="*" element={<PageNotFound />} />
        <Route path='serverError' element={<ServerError/>}/>
      </Routes>
    </>
  );
}

export default App;
