import React, { useState,useRef,useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import  '../Componant Style/paneau.css'

import axios from 'axios';
import { baseURL } from '../config.js';

import addIcon from '../assets/addIcon.svg'
import imageAdd from '../assets/imageAdd.svg'
import remove from '../assets/remove.svg'

export default function Paneau() {

    const navigate = useNavigate()
    const [viewImages, setViewImages] = useState(false)
    const [selectedFiles, setSelectedFiles] = useState([]);
    const fileInputRef = useRef(null);
    const base64Images = [];




  // Function to check if two byte arrays are equal
  const areArraysEqual = (array1, array2) => {
    if (array1.length !== array2.length) {
      return false;
    }

    for (let i = 0; i < array1.length; i++) {
      if (array1[i] !== array2[i]) {
        return false;
      }
    }

    return true;
  };


// Nettoyage des URL d'objet lors du démontage du composant
useEffect(() => {
    return () => {
        selectedFiles.forEach((file) => URL.revokeObjectURL(file));
    };
}, [selectedFiles]);

const updateViewImages = (files) => {
    if (files.length > 0 && !viewImages) {
        setViewImages(true);
    } else if (files.length === 0 && viewImages) {
        setViewImages(false);
    }
};


//Function to handling input file changing
const handleFileChange = (event) => {
    const files = event.target.files;

    if (files.length + selectedFiles.length < 5) {
        if (files.length > 0 && selectedFiles.length === 0) {
            const fileList = Array.from(files);
            setSelectedFiles(fileList);
            updateViewImages(fileList); // Mettre à jour viewImages
        } else if (files.length > 0 && selectedFiles.length > 0) {
            const fileList = Array.from(files);
            const updatedSelectedFiles = selectedFiles.concat(fileList);
            setSelectedFiles(updatedSelectedFiles);
            updateViewImages(updatedSelectedFiles); // Mettre à jour viewImages
        }
    } else {
        alert("max is 4 pictures");
    }
};


    //Funciton that convert arrayfile to arrayByte
    function convertToBase64(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
      
          reader.onload = (event) => {
            resolve(event.target.result);
          };
      
          reader.onerror = (error) => {
            reject(error);
          };
      
          reader.readAsDataURL(file);
        });
      }
    

  async function convertImagesToBase64(imageFiles) {

  
    for (const file of imageFiles) {
      try {
        const base64Image = await convertToBase64(file);
        base64Images.push(base64Image);
      } catch (error) {
        console.error('Error converting image to Base64:', error);
      }
    }
  
    return base64Images;
  }



  const hasDuplicates = (array) => {
    for (let i = 0; i < array.length; i++) {
      for (let j = i + 1; j < array.length; j++) {
        if (areArraysEqual(array[i], array[j])) {
          return true; // Found duplicates
        }
      }
    }
    return false; 
  };



    //Function to handle the delete image button Clicked
    const handleButtonClick = (index) => {

        const updatedFiles = [...selectedFiles];
        updatedFiles.splice(index, 1);
        setSelectedFiles(updatedFiles);
      
        };


          //Function that hundle when the button send data in clicked
  const handleClick = (e) => { 

    let isDuplicat;
    e.preventDefault();
    
 if(selectedFiles.length===0){
      alert("Selectionner les images")
    }else if(selectedFiles.length<4){
        alert("il faut etre exactement 4 image")
    }else{
      convertImagesToBase64(selectedFiles).then((base64Images) => {
        isDuplicat = hasDuplicates(base64Images)
        if(isDuplicat ){
          alert ("les photos sont dupliquées");
        }else{
          const formData = new FormData();
          selectedFiles.forEach((image) => {
            formData.append('images', image);
          });
          axios.post(`${baseURL}/insertImagePanneau`, formData
          )
          .then((response) => {
            if(response.status=== 200){
              navigate('/Dashboard/Dashboard');
              alert('Data Added correctly')
            }else{
              navigate('/Dashboard/serverError');
            }
          })
          .catch((error) => {
            
            alert("Erreur de serveur", error)
          });
        }
      });
    }
  }


  return (

<div className='addContainerDashboard'>
    <div className='headerAddProduct'>
    <p id='addProductTxt'>Add Product</p>
    <div className='headerAddProductCA'>
      <Link to='/Dashboard/Dashboard' id='textGris' >Cancel</Link>
      <div onClick={handleClick}>
        <p>Add</p>
        <img src={addIcon} alt=''/>
      </div>
    </div>
  </div>
    <div className='inputsAddProduct'>
    <div className='inputImages'>
      
    <div className={`${viewImages ? `viewImage` : `inputAdd`}`} >
      <img  src={imageAdd}  alt=''/>
      <p>Add images from your device <strong onClick={() => fileInputRef.current.click()}>Browse</strong> </p>
      <input
      type="file"
      accept="image/*"
      style={{ display: 'none' }}
      ref={fileInputRef}
      onChange={handleFileChange}
      multiple
      />
    </div>
    
    <div className={`${viewImages ? `imagesProducts` : `viewImage`}`}>
        {selectedFiles.map((file, index) => (
          <div key={index} className='imageContainerInputs'>
            <div className='inputFile'>
              <img
                className='imageProduct'
                key={index}
                src={URL.createObjectURL(file)}
                alt={`Selected  ${index}`}
              />
            </div>
            <div className='addDelete'>
              <img src={remove} alt='' className='delete' onClick={() => handleButtonClick(index)}/>
              <hr/>
              <p className='add' onClick={() => fileInputRef.current.click()}>+</p>
            </div>
          </div>
        ))}
    </div>
  </div>
  </div>
  </div>
  )
}
