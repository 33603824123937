import React from 'react'
import { Link } from 'react-router-dom'
import '../Componant Style/ListProduct.css'
 
import noData from '../assets/noData.png'
 
import Product from './Product'

export default function ListProduct(props) {
  return (
    <div className='allProduct'>

        <div className='headerListProduct'>
            <p>{props.title}</p>
            <Link className='seeAll' to="/Product/Choisir%20la%20categorie">See All</Link>
        </div>  
        <div className={`'containerProducts' ${Array.isArray(props.data)&& props.data.length === 3 ? 'spaceBetween' :'gap'}`}>
        {Array.isArray(props.data) && props.data.length > 0 ? (
          props.data.map((item, index) => (
              <Product
                key={index}
                id={item.idproduct}
                image = {item.productImagePath}
                pro={props.title}
                model={item.model}
                brand={item.brand}
                prix={item.prix} 
                promotion={item.promotion}
                getNumberOfElementInPanel = {props.getNumberOfElementInPanel}
              />
          ))
          ) : (
            <div className='noData'>
            <img src={noData} alt="aucun produit" />
            <p>{props.title} est pas Disponible</p>
            </div>
          )}
          
      </div>

  </div>
  )
}
