import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { baseURL } from '../config.js';
import remove from '../assets/remove.svg';
import eye from '../assets/eye.svg';
import DeleteCountainer from './DeleteCountainer';
import { Link } from 'react-router-dom';

export default function TablePrinter(props) {
  const serverBaseUrl = `${baseURL}`;

  const [delConf, setDelConf] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [data, setData] = useState([]);

  const showDeletConfermation = (id) => {
    setDeleteId(id);
    setDelConf(true);
  };

  const fetchData = useCallback(async () => {
    try {
      const response = await axios.get(`${baseURL}/getData?compare=${props.type}`);
      setData(response.data);
    } catch (error) {
      console.error('Error:', error);
    }
  }, [props.type]);

  const onClickDeleteOk = () => {
    axios
      .delete(`${baseURL}/deleteDataAdemin?id=${deleteId}`)
      .then((response) => {
        // Handle success
        console.log('Delete successful', response.data);
        setDelConf(false);
        // Update the state with the new data
        fetchData();
      })
      .catch((error) => {
        // Handle error
        alert('Error deleting resource', error);
      });
  };

  useEffect(() => {
    // Call fetchData when the component mounts or when props.type changes
    fetchData();
  }, [fetchData, props.type]);

  return (
    <div className='containerTable'>
      <div>
        <p className='titleTables'>All Products</p>
        <table>
          <thead>
            <tr>
              <th>
                <p>Image</p>
              </th>
              <th>Brand</th>
              <th>Model</th>
              <th colSpan={3}>Description</th>
              <th>Type</th>
              <th>Prix</th>
              <th>promo</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td>
                  <img className='imageProduct' src={serverBaseUrl + '/uploads/' + item.productImagePath} alt='' />
                </td>
                <td>{item.brand}</td>
                <td>{item.model}</td>
                <td colSpan={3} className='descTable'>
                  {item.description.replace(/%%%/g, ' ')}
                </td>
                <td>{item.processeur === null ? item.type : item.processeur + ' ' + item.generation}</td>
                <td>{item.prix}</td>
                <td>{parseInt(item.promotion) === 0 ? 'non' : 'oui'}</td>
                <td className='images'>
                  <img className='supptable' src={remove} onClick={() => showDeletConfermation(item.idproduct)} alt='remove' key={item.idproduct} />
                  <Link to={`/Dashboard/DetailProductAdmin/${item.idproduct}`}>
                    <img className='eyetable' src={eye} alt='eye' key={item.idproduct} />
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <DeleteCountainer state={delConf} setstate={setDelConf} onClickDeleteOk={onClickDeleteOk} />
    </div>
  );
}
