import React, {useEffect, useState} from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import axios from 'axios'
import '../Page Style/Dashboard.css'
import { baseURL } from '../config.js';


import SideBar from '../Componant/SideBar'
import LaptopsContainer from '../Componant/LaptopsContainer'
import PrinterContainer from '../Componant/PrinterContainer'
import AddLaptop from '../Componant/AddLaptop'
import AddPrinter from '../Componant/AddPrinter'
import AddProduct from '../Componant/AddProduct'
import AddCat from '../Componant/AddCat'
import ProductContainer from '../Componant/ProductContainer'
import CommandAdmin from '../Componant/CommandAdmin'
import Paneau from '../Componant/Paneau'



export default function Dashboard() {

  
  const { filter } = useParams();

  const [souscat, setSousCat] = useState([])
  
  const location = useLocation();

  const navigate = useNavigate();
  
  const [sousCatResume, setSousCatResume] = useState([])

  useEffect(()=>{
    const token = localStorage.getItem('jwt');
    
    const fetchData = async () => {
      try {
        // First Axios GET request
        const response = await axios.post(`${baseURL}/profile`, {token})
        if(response.data.message){
          navigate('/login');
        }
  
        const response2 = await axios.get(`${baseURL}/getSousCategories`);
        setSousCat(response2.data)
  
      } catch (error) {
        // Handle errors for both requests here
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();

    setSousCatResume(souscat.map(obj => obj.sous_categorie_name));

  },[navigate,location.pathname,souscat])
 


  let content;
  switch (filter){
    case'addPrinter':
      content=<AddPrinter/>
      break;
    case'addLaptop':
      content=<AddLaptop/>
      break;
    case'addProduct':
      content=<AddProduct/>
      break;
    case'addCat':
      content = <AddCat/>
      break;
    case'Commandes':
      content = <CommandAdmin/>
      break;
    case'Panneau':
      content = <Paneau/>
      break;
    default :
        if (sousCatResume.includes(filter)) {
          
          const foundCategory = souscat.find(item => item.sous_categorie_name === filter);
          if (foundCategory.categorie_name==="Ordinateur" || foundCategory.categorie_name==="Ordinateurs" || foundCategory.categorie_name==="ordinateur"|| foundCategory.categorie_name==="ordinateurs"){
            content = <LaptopsContainer type = {filter} />;

          }else if(foundCategory.categorie_name==="imprement" || foundCategory.categorie_name==="imprements" || foundCategory.categorie_name==="Imprements" || foundCategory.categorie_name==="Imprement" ){
            content = <PrinterContainer type = {filter} />;
          }else{
            content = <ProductContainer type = {filter} />;

          }
        } else {
          content = <p>Welcome <strong>FORCE INFORMATIQUE</strong></p>
        }
      
  }

  return (
    <div className='dashboardContainer'>
      <SideBar/>

      

      <div className='allDashboards'>
        {content} 

      </div>
      
    </div>
  )
}
