import React from 'react'
import Header from '../Componant/Header'
import Footer from '../Componant/Footer'

export default function Partner() {
  return (
    <div>
    <Header/>
      partner
    <Footer/>
    </div>
  )
}
