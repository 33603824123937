import React, { useState, useEffect } from 'react'
import {Link, useParams} from 'react-router-dom'
import axios from 'axios'
import { baseURL } from '../config.js';

import '../Page Style/Dashboard.css'
import '../Componant Style/detailProductAdmin.css'

import SideBar from '../Componant/SideBar'

export default function DetailProduct() {

  const serverBaseUrl = `${baseURL}`;

  const { productId } = useParams();
  const [data, setData] = useState({})
  const [dataDetail, setDataDetail] = useState({})
  const [arrayDesc, setArrayDesc] = useState([])
  
useEffect(() => {
  axios.get(`${baseURL}/DetailProduct?id=${productId}`)
    .then((response) => {
      // Handle the response from the backend here
      setData(response.data);
      setDataDetail(response.data[0]);
      setArrayDesc(response.data[0].description.split(/%%%/));
      window.scrollTo(0, 0);
    })
    .catch((error) => {
      console.error('Error:', error);
    });

}, []);



  return (

    <div className='dashboardContainer'>
    <SideBar/>
    <div className='allDashboards'>
    <div className='detailProductContainer'>
      <div className='headerShouwingCar'>
        <p id='nameProductHeader'>{dataDetail.brand} {dataDetail.model}</p>
        <Link to='/Dashboard/Dashboard' id='backHeader'>Back</Link>
      </div>
 
      <div className='productDetailCountainer'>
        <div className='productDetail'>
           <div className='imageContainerShowCar'>
              <img src={serverBaseUrl + '/uploads/' + dataDetail.productImagePath} alt=''/>
           </div>
           <div className='fichPrice'>
                 <a href={dataDetail.ficheTechnique?.startsWith('http') ? dataDetail.ficheTechnique : `http://${dataDetail.ficheTechnique}`} target='_blank'>
                    Fiche technique
                 </a>

                 <div className='priceShowingCar'>
                  {dataDetail.promotion > 0 ? <p id='priceBefore'>{dataDetail.prix} DA</p> : null}
                  <p id='priceAfter'>{dataDetail.prix - dataDetail.prix * dataDetail.promotion/100} DA</p>
               </div>
           </div>
           <div className='descriptionContainer'>
              <p className='descriptionTitle'>Description</p>
              <div className='descriptionContent'>

                  {arrayDesc && arrayDesc.map((item, index) => (
                    <p key={index}>{item}</p>
                  ))}

             
              </div>
           </div>
        </div>
      </div>
    </div>
    </div>
    </div>
  
  )
}
