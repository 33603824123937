import React, {useState, useEffect} from 'react'
import axios from 'axios'


import Header from '../Componant/Header'
import Footer from '../Componant/Footer'
import CommandLine from '../Componant/CommandLine'

import { baseURL } from '../config.js';
import '../Page Style/panle.css'

import noData from '../assets/noData.png'
import commander from '../assets/commander.png'




export default function CommandClient() {


  const [commandState, setCommandState] = useState(false)
  const [panelN, setPanel] = useState()
  const [panelData, setpanelData] = useState({})
  const [refrech, setRefreche] = useState(false)
  const [totalPrice, setTotalPrice] = useState(0);
  const [wilayas, setWilaya] = useState({})
  const [communs, setCommuns] = useState({})
  const [communsFiltred, setCommunsFiltred] = useState({})

  const [dataCommand, setDataCommand] = useState({
    nom:"",
    prenom:"",
    wilaya:"",
    commun:"",
    adresse:"", 
    numero:""
  })

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDataCommand((prevValues) => ({
        ...prevValues,
        [name]: value,
    }));
  };

  function trimAndCheckEmptyFields(data) {
    const trimmedData = {};
  
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const value = data[key];
        const trimmedValue = value ? value.trim() : '';
  
        if (trimmedValue === '') {
          // If trimmed value is empty, you can handle it as needed (e.g., set to null)
          return null;
        }
  
        trimmedData[key] = trimmedValue;
      }
    }
  
    return trimmedData;
  }
  


  const getNumberOfElementInPanel = () =>{

    const storedArray = JSON.parse(localStorage.getItem('id'));
    const lengthOfArray = storedArray ? storedArray.length : 0;
    
    setPanel(lengthOfArray);
  
  } 

  const handleAnnulerClick = () => {
    if(commandState){
      setCommandState(false)
      setCommunsFiltred({})
    }
  }

  const handleChangeWilayas = (e) => {
    setCommunsFiltred({}); // Assuming you want to reset the state before filtering
  
    const targetValue = e.target.value;
    const foundObject = wilayas.find(obj => obj.nom === targetValue);
  
    if (foundObject) {
      if (communs && Object.entries(communs).length > 0) {
        const filteredCommuns = Object.entries(communs)
          .filter(([index, item]) => foundObject.code === item.wilaya_id)
          .reduce((acc, [index, item]) => {
            acc[index] = item;
            return acc;
          }, {});
  
        setCommunsFiltred(filteredCommuns);
      }
    }
  };

  const handlechangeSelectCommand = (e) => {
    handleChangeWilayas(e)
    handleInputChange(e)
  }

  const sendData = async () => {
    try {

      const existingArrayString = localStorage.getItem('id');
      const existingArray = existingArrayString ? JSON.parse(existingArrayString) : [];

      // Encode search parameters
      const nom = encodeURIComponent(dataCommand.nom);
      const prenom = encodeURIComponent(dataCommand.prenom);
      const wilaya = encodeURIComponent(dataCommand.wilaya);
      const commun = encodeURIComponent(dataCommand.commun);
      const adresse = encodeURIComponent(dataCommand.adresse);
      const numero = encodeURIComponent(dataCommand.numero);
      const price = encodeURIComponent(totalPrice)

      // Construct the search URL with encoded parameters
      let searchUrl = `${baseURL}/sendCommand?nom=${nom}&prenom=${prenom}&wilaya=${wilaya}&commun=${commun}&adresse=${adresse}&numero=${numero}&price=${price}`;
      
      existingArray.forEach(item => {
        searchUrl += `&id=${item.id}&quantite=${item.quantite}`; // Add more parameters as needed
      });

      console.log(searchUrl)

      // Fetch data using the constructed URL
      const result1 = await axios.post(searchUrl);
      console.log(result1)
      if(result1.status === 200){
        alert("Command effectuer")
      }else{
        alert("Erreur de serveur")
      }
    } catch (error) {
      // Handle errors for both requests here
      alert("Erreur de serveur")
      console.error('Error fetching data:', error);
    }
  };


  

  const handleCommandClick = () => {
    if(!commandState){
      if(panelN){
        setCommandState(true)
        setWilaya(wilayas.nom)
      }else{
        alert('no command to send')
      }  
    }else{
      const trimmedData = trimAndCheckEmptyFields(dataCommand);

      if (trimmedData !== null) {
        sendData();
        setDataCommand({
          nom: "",
          prenom: "",
          wilaya: "",
          commun: "",
          adresse: "",
          numero: ""
        });
        setCommandState(false)
      } else {
        alert("Check all data")
      }
      
    }
  }



  const calculeTotalPrice = () => {
    const existingArrayString = localStorage.getItem('id');
    const existingArray = existingArrayString ? JSON.parse(existingArrayString) : [];
    const tableID = existingArray.map(item => item.id);
    const tableQuantite = existingArray.map(item => item.quantite)
    const allPrices = tableID.flatMap(id =>
      panelData
        .filter(item => item.idproduct === id && item.prix !== undefined)
        .map(item => item.prix - (item.prix * item.promotion/100))
    );

    const totalValue = tableID.reduce((total, id, index) => {
      const correspondingPrice = allPrices[index] || 0; // Use 0 if price is not available
      const correspondingQuantite = tableQuantite[index] || 0; // Use 0 if quantite is not available
    
      return total + correspondingPrice * correspondingQuantite;
    }, 0);
    
    setTotalPrice(totalValue);
    
  }




  useEffect(() => {
    const fetchData = async () => {
      getNumberOfElementInPanel();
      
      // Get the array of objects from local storage
      const existingArrayString = localStorage.getItem('id');
      const existingArray = existingArrayString ? JSON.parse(existingArrayString) : [];
  
      // Extract only the IDs from the array
      const idArray = existingArray.map(item => item.id);
      try {
        // Use the idArray directly in the axios request
        const response2 = await axios.get(`${baseURL}/panelData?condition=${JSON.stringify(idArray)}`);
        setpanelData(response2.data);

        const response1 = await axios.get(`${baseURL}/getWiaya`);
        setWilaya(response1.data);

        const response3 = await axios.get(`${baseURL}/getCommuns`);
        setCommuns(response3.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      
    };
  
    fetchData(); // Call the fetchData function
  }, [refrech, wilayas, communs, panelData]);

  return (
    <div>
      <Header/>
      <div className="commandPanelContainer">
        {commandState ? <p id='panelTitle'>
          Commande
        </p> : <p id='panelTitle'>
          Panier
        </p>}
        <div className='commandPrixContainer'>
        {commandState ? 
          <div className='commandContainer'>
            <div className='inputesCommandContainer'>
              <div>
                <input type='text' placeholder='Nom' name='nom' onChange={handleInputChange} value={dataCommand.nom} className='leftInputCommand inputCommand'/>
                <input type='text' placeholder='Prénom' name='prenom' onChange={handleInputChange} value={dataCommand.prenom} className='rightInputCommand inputCommand' />
              </div>
              <div>
                <select className='leftInputCommand inputCommand' value={dataCommand.wilaya} name='wilaya' onChange={(event) => handlechangeSelectCommand(event)}>
                  <option>wilayas</option>
                  {wilayas && Object.entries(wilayas).length > 0 &&
                    Object.entries(wilayas).map(([index, item]) => (
                    <option key={index} value={item.nom}>{item.nom}</option>
                  ))}
                </select>
                <select className='rightInputCommand inputCommand' name='commun' onChange={handleInputChange} value={dataCommand.commun}>
                  <option>Communs</option>
                {communsFiltred && Object.entries(communsFiltred).length > 0 &&
                    Object.entries(communsFiltred).map(([index, item]) => (
                    <option key={index} value={item.nom}>{item.nom}</option>
                  ))}
                </select>
              </div>
              <div>
                <input type='text' placeholder='Adresse' className='leftInputCommand inputCommand' name='adresse' onChange={handleInputChange} value={dataCommand.adresse}/>
                <input type='number' placeholder='Numero de telephone' className='rightInputCommand inputCommand' name='numero' onChange={handleInputChange} value={dataCommand.numero}/>
              </div>
            </div>
          </div>
        :<div className='commandContainer'>
            {panelN ?
              panelData && Array.isArray(panelData) && panelData.map((item, index) => (
                <CommandLine key={index} data = {item} functionRefrech = {calculeTotalPrice} />
              ))
            : <div id='noData'><img src={noData}/><p>no Data</p></div>}
          </div>}
          
          <div className='prixContainer'>
            <div>
            <p id='totalTitle'>Total</p>
            <p id='prixPanel'>{totalPrice} DA</p>
            <div >
                {commandState && <p id='annulerCommand' onClick={handleAnnulerClick}>Annuler</p>}
                <p id='commanderPanel' onClick={handleCommandClick}>{commandState && <img src={commander}/>}Commander</p>
            </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  )
}
