import React from 'react'

import '../Page Style/pageNotFound.css'

import Header from '../Componant/Header'
import Footer from '../Componant/Footer'


export default function ServerError() {
  return (
    <div>
              <Header/>
            <div className='container404'>
            <p id='P404'>500</p>
            <p id='pageN'>Server Error</p>
            </div>
        <Footer/>
    </div>
  )
}
