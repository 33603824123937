import React from 'react'

import '../Componant Style/tablesAdmin.css'

import DashboardHeadLaptop from './DashboardHeadLaptop'
import TablePrinter from './TablePrinter'

export default function LaptopsContainer(props) {

  return (
    <div className='dataShows'>
      <DashboardHeadLaptop/>
      <TablePrinter type={props.type}/>
    </div>
  )
}
