import React from 'react'

import '../Componant Style/tablesAdmin.css'

import DashborardHeadProduct from './DashborardHeadProduct'
import TablePrinter from './TablePrinter'

export default function ProductContainer(props) {
  return (
    <div className='dataShows'>
      <DashborardHeadProduct/>
      <TablePrinter type={props.type}/>
    </div>
  ) 
}
  