import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../Componant Style/SlideHome.css';
import { baseURL } from '../config.js';


export default function SlideHome() {
    const serverBaseUrl = `${baseURL}`;
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [images, setImages] = useState([]);

    useEffect(() => {
        const fetchImages = async () => {
            try {
                const response = await axios.get(`${baseURL}/getImagesPanneau`);
                setImages(response.data);
            } catch (error) {
                console.error('Error fetching images:', error);
            }
        };

        fetchImages();
    }, []);

    

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) =>
                prevIndex === images.length - 1 ? 0 : prevIndex + 1
            );
        }, 2000); // Change image every 3 seconds

        return () => {
            clearInterval(interval);
        };
    }, [images]); // Include images in the dependency array

    return (
        <div className="image-gallery">
            <div className="image-container">
                {images.length > 0 && (
                    <img src={serverBaseUrl + '/uploads/' + images[currentImageIndex]} alt="" />
                )}
            </div>
            <div className="navigation-indicators">
                {images.map((_, index) => (
                    <div
                        key={index}
                        className={`indicator ${index === currentImageIndex ? 'active' : ''}`}
                    ></div>
                ))}
            </div>
        </div>
    );
}
