import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'

import { baseURL } from '../config.js';

import remove from '../assets/remove.svg';
import eye from '../assets/eye.svg';

import '../Componant Style/commandLine.css'

export default function CommandLine(props) {

  const serverBaseUrl = `${baseURL}`;

  const [quantity, setQuantity] = useState(() => {
    const storedData = JSON.parse(localStorage.getItem('id'));
    const product = storedData.find((item) => item.id === props.data.idproduct);
    return product ? product.quantite : 1;
  }); 

  const [yourData, setYourData] = useState();

  const [notRealPrice, setNotRealPrice] = useState(() => {
    const price = props.data.prix - (props.data.prix * props.data.promotion) / 100;
    return price * quantity;
  });

  useEffect(() => {
    // Update notRealPrice whenever quantity or other relevant data changes
    props.functionRefrech();
    const price = props.data.prix - (props.data.prix * props.data.promotion) / 100;
    setNotRealPrice(price * quantity);
  }, [quantity, props.data.prix, props.data.promotion]);

  
  const handleClick = (valueToRemove) => {
    const updatedData = JSON.parse(localStorage.getItem('id')).filter((item) => item.id !== valueToRemove);

    // Set the updated array directly
    setYourData(updatedData);
  
    // Update local storage with the updated array 
    const updatedArrayString = JSON.stringify(updatedData);
    localStorage.setItem('id', updatedArrayString);
  
    props.functionRefrech();

  };
   

  const handleIncreaseQuantity = () => {
    setQuantity((prev) => prev + 1);

    // Update the quantity in the local storage
    updateQuantityInLocalStorage(props.data.idproduct, quantity + 1);
  };

  const handleDecreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity((prev) => prev - 1);

      // Update the quantity in the local storage
      updateQuantityInLocalStorage(props.data.idproduct, quantity - 1);
    }
    
  };

  const updateQuantityInLocalStorage = (productId, newQuantity) => {
    const storedData = JSON.parse(localStorage.getItem('id')) || [];
    const updatedData = storedData.map((item) =>
      item.id === productId ? { ...item, quantite: newQuantity } : item
    );
    localStorage.setItem('id', JSON.stringify(updatedData));
    props.functionRefrech();
   
  };
  

  return (
    <div className='commandLineContainer'>
      <div className='commandLineImgName'>
        <img src={serverBaseUrl + '/uploads/' + props.data.productImagePath} alt='Product' />
        <div>
          <p className='nameProduct'>
            {props.data.brand} {props.data.model}
          </p>
          <p className='sousCatProduct'>{props.data.type}</p>
        </div>
      </div>
      <div className='commandLineQuantPrixDelete'>
        <div className='quantityPrice'>
          <p className='changeQuantiteComand' onClick={handleIncreaseQuantity}>
            +
          </p>
          <p>{quantity}</p>
          <p
            className='changeQuantiteComand'
            onClick={handleDecreaseQuantity}
          >
            -
          </p>
        </div>
        <p className='prixCommnadProduct'>{notRealPrice} DA</p>
        <div className='vueDeleteCommand'>
          <img id='removeVue' onClick={() => handleClick(props.data.idproduct)} src={remove} alt='Remove' />
          <Link id='linkEye' to={`/ProductDetail/${props.data.idproduct}`}><img id='removeVue' src={eye} alt='View' /></Link>
        </div>
      </div>
    </div>
  );
}
